<template>
  <div>
    <div id="wrapper" class="wrapper-fluid banners-effect-3">
      <header id="header" class="typeheader-1 typeheader-2 merchant-new-hdr">
        <div style="position: absolute; top: 0; display: none;" class="header-top">
          <div class="container">
            <div class="row">
              <div class="header-top-left col-lg-7 col-md-8 col-sm-6 col-xs-4">
                <ul class="top-link list-inline hidden-lg">
                  <li class="account" id="my_account">
                    <a title="My Account " class="btn-xs dropdown-toggle" data-toggle="dropdown">
                      <span class="hidden-xs">&nbsp;My Account</span>&nbsp;&nbsp;
                      <span class="fa fa-caret-down"></span>
                    </a>
                  
                    <ul class="dropdown-menu">
                      <li v-if="this.$store.state.merchant_accessToken !== null">
                        <a @click="pushMerchantProfile">
                          <i class="fa fa-user"></i>&nbsp;&nbsp;Profile
                        </a>
                      </li>

                      <li v-if="this.$store.state.merchant_accessToken === null" class="log login">
                        <a @click="login">
                          <i class="fa fa-pencil-square-o"></i>
                          &nbsp;&nbsp;Login
                        </a>
                      </li>

                      <li v-if="this.$store.state.merchant_accessToken === null" class="log login">
                        <a @click="register">
                          <i class="fa fa-user"></i> &nbsp;&nbsp;Register
                        </a>
                      </li>

                      <li v-if="this.$store.state.merchant_accessToken !== null">
                        <a @click="logout">
                          <i class="fa fa-lock"></i>&nbsp;&nbsp;Log Out
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!-- <div class="hidden-md hidden-sm hidden-xs welcome-msg">Welcome to SuperMarket! Wrap new offers / gift every single day on Weekends - New Coupon code: <span>H2020PP01</span> </div>        -->
              </div>
              <div
                style="display: none;"
                class="header-top-right col-lg-5 col-md-4 col-sm-6 col-xs-8"
              >
                <div class="hidden-md hidden-sm hidden-xs welcome-msg" @click="handleByOnSlt">
                  <a>Buy on Cochchi</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="header-middle">
            <div class="container">
                <div class="row" style="margin-bottom: 10px;">
                    <div class="navbar-logo col-lg-2 col-md-3 col-sm-4 col-xs-9">
                        <div class="logo merch-logo">
                            <a @click="handleByOnSlt"><img src="https://res.cloudinary.com/persystance-network/image/upload/v1586340836/slt/portal_user/1586340836780.png" title="Your Store" alt="Your Store" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <div
          class="header-middle merch-header-middle"
          style="margin-top: 0px;margin-bottom: 0px;adding-top: 0pxpadding-bottom: 0px;"
        >
          <!-- <div class="container-fluid" style="min-height: 34px;">
                <div class="navbar-logo col-lg-2 col-md-3 col-sm-4 col-xs-6" style="position: absolute;">
                
                
                <div style=" margin-left: -30px;">
                    <nav class="navbar navbar-dark" style="margin-left: -30px; margin-top: -20px; height: 66px;">
                    <a class="navbar-brand" href="#">
                        <img src="https://res.cloudinary.com/persystance-network/image/upload/v1589092077/slt/portal_user/1589092077321.png" alt="mdb logo" style="margin-top: 5px; margin-left: 4%; width: 79%; height: auto;">
                    </a>
                    </nav>
                </div>

                </div>

                <div class="ol-lg-10 col-md-9 col-sm-8 col-xs-6 header-portal-title">
                        Sathosa Merchant
                    </div>
          </div>-->

          <div class="container" style="min-height: 90px;padding-left: 0px;padding-right: 0px;">
            <!-- <div class="navbar-logo col-lg-3 col-md-3 col-sm-4 col-xs-7" style="position: absolute;">
                    <div style=" margin-left: -30px;">
                        <nav class="navbar navbar-dark" style="margin-left: -30px; margin-top: 0px; height: 66px;">
                            <a class="navbar-brand" @click="merchantOderGroup()">
                            <img  src="https://res.cloudinary.com/persystance-network/image/upload/v1589092077/slt/portal_user/1589092077321.png" alt="mdb logo" style="margin-top: -16px; margin-left: 3%; width: 79%; height: auto;min-height: 100px;">
                            </a>
                        </nav>
                    </div>

            </div>-->
            <div class="row">
              <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 merch-logo-section" id="logosection" style="padding-bottom: 2px;">
                <div>
                <!-- <div class="nav-logo"> -->
                  
                  <a @click="merchantOderGroup()">
                    <img
                      :src="this.$store.state.colourLogo"
                      alt="mdb logo"
                      style="border-radius: 10px;"
                    />
                  </a>
                </div>
              </div>

              <div class="col-lg-10 col-md-9 col-sm-9 col-xs-12 header-portal-title merchant-header-portal-title">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  v-if="this.$store.state.merchant_accessToken === null">
                    <B>Cochchi Merchant</B>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 admin-heading"  v-if="this.$store.state.merchant_accessToken != null && userRole === 1">
                    <!-- <B>Cochchi Admin</B> -->
                    <ul class="signin-link blank merch-top-links ">
                    <li v-if="this.$store.state.merchant_accessToken !== null" style="margin-right: 5px;">
                      <!-- <a href="./HelpCenterHome.html" target="_blank"> -->
                        <!-- <i class="fa fa-comments text-color-headder"></i>&nbsp;&nbsp; -->
                        <B>Cochchi Merchant | </B>
                      <!-- </a> -->
                      <a href="./HelpCenterHome.html" target="_blank">
                        <!-- <i class="fa fa-comments text-color-headder"></i>&nbsp;&nbsp; -->
                        <B class="text-color-headder">Help Center</B>
                      </a>
                    </li>
                    </ul>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  v-if="this.$store.state.merchant_accessToken != null && userRole === 2">
                    <B>Cochchi Merchant</B>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-bottom hidden-compact merchant-header-bottom" style="margin-top: -22px;">
          <div class="container">
            <div class="row">
              <div class="bottom3 merchant-header-link-text">
                <div
                  class="signin-w hidden-md hidden-sm hidden-xs"
                  v-if="this.$store.state.merchant_accessToken === null"
                >
                  <ul class="signin-link blank">
                    <!-- <li class="log login"><i class="fa fa-lock"></i> <a class="link-lg" @click="login">&nbsp;&nbsp;Login</a></li>                                 -->
                  </ul>
                </div>

                <div
                  class="signin-w"
                  v-if="this.$store.state.merchant_accessToken != null"
                >
                  <ul class="signin-link blank merch-top-links">
                    <li class="log login">
                      <i class="fa fa-unlock text-color-headder"></i>
                      <a class="link-lg" @click="logout">
                        &nbsp;&nbsp;
                        <B class="text-color-headder">Log Out</B>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="signin-w">
                  <ul class="signin-link blank merch-top-links">
                    <div class="dropdown" style="display: initial;" @mouseover="resetWindow()">
                      <li v-if="this.$store.state.merchant_accessToken !== null">
                        <a
                          id="dropdownMenuAlertButton"
                          class="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-bell text-color-headder"></i>
                          <b-badge
                            pill
                            variant="success"
                            class="mb-2"
                            style="padding: 4px;
                                                border-radius: 50%;background-color: red; margin-bottom: 3px; font-size: 8px;"
                          >{{ alerts.length }}</b-badge>&nbsp;&nbsp;
                          <B class="text-color-headder">Alerts</B>
                        </a>
                      </li>
                      <div
                        v-show="alerts.length != 0"
                        class="dropdown-menu"
                        id="dropdownwindow"
                        aria-labelledby="dropdownMenuAlertButton"
                        style="padding: 10px; width: 450px; display: inline-grid; margin-top: 35px; position:absolute;"
                      >
                        <div style="max-height:300px; overflow: auto; ">
                          <table
                            class="table table-borderless hoverTable tble-alerts"
                            style="margin-bottom: 0px;"
                          >
                            <tr
                              role="menuitem"
                              style="cursor: pointer;"
                              v-for="(item, index) in alerts"
                              :key="index"
                              @click="alertPush(item)"
                            >
                              <td
                                style="; padding:2px; display: flex;
                                                        align-items: center;justify-content: center;"
                              >
                                <img
                                  data-v-596f113e
                                  width="200px"
                                  :src="item.image"
                                  alt="Thumb Image"
                                  title="Thumb Image"
                                  class="img-thumbnail"
                                />
                              </td>
                              <td
                                style="padding: 10px; color: black;"
                              >{{ item.notification }}</td>
                            </tr>
                          </table>
                        </div>

                        <!-- <div class="signin-w">
                                            <hr style="margin: 2px;">
                                            <div class="col-md-12" style="text-align: center; padding-right: 0px;"><a>View More</a></div>
                        </div>-->
                      </div>
                    </div>
                  </ul>
                </div>

                <div
                  class="signin-w"
                  v-if="this.$store.state.merchant_accessToken != null"
                >

                  
                  <ul class="signin-link blank merch-top-links ">

                    <li v-if="this.$store.state.merchant_accessToken !== null" style="margin-right: 5px;">
                      <a @click="getChatUsers()" data-toggle="modal" data-target="#mdlMerchantChat">
                        <i class="fa fa-comments text-color-headder"></i>&nbsp;&nbsp;
                        <B class="text-color-headder">Chat Now</B>
                      </a>
                    </li>

                    <li v-if="this.$store.state.merchant_accessToken !== null" class="signin-w">
                      <a @click="pushMerchantProfile">
                        <i class="fa fa-user text-color-headder"></i>&nbsp;&nbsp;
                        <B class="text-color-headder">Profile</B>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="bottom3">                        
                        <div class="telephone hidden-xs hidden-sm hidden-md">
                            <ul class="signin-link blank"> 
                                <li v-if="this.$store.state.merchant_accessToken !== null" class="log login">
                                    <i class="fa fa-lock"></i>&nbsp;&nbsp;
                                    <a @click="login" class="link-lg">Login </a> &nbsp;or &nbsp;
                                    <a @click="register">Register</a>
                                </li>  
                                <li><a @click="handleMerchantProfile">Merchant Profile</a></li> 
                                <li><a><i class="fa fa-phone-square"></i>Hotline (+123)4 567 890</a></li> 
                            </ul>
                        </div>    
              </div>-->
            </div>
          </div>
        </div>
      </header>
      <!-- Merchant Chat Modal -->
      <div
        class="modal"
        id="mdlMerchantChat"
        tabindex="-1"
        role="dialog"
        style="right:0px !important; top: auto; bottom: 0px;  left:auto;"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border-radius: 0px !important; ">
            <div class="modal-body">
              <CommonChatWindow
                ref="childChatComm"
                v-bind:utype="'merchant'"
                v-bind:chatTargets="chatUsers"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Merchant Chat Modal End -->
    </div>
  </div>
</template>
<script>
import APIProfile from "../../mixins/merchant/APIProfile";
import mixinStore from "../../mixins/merchant/APIStore";
import CommonChatWindow from "@/components/common/chatCommon.vue";
export default {
  name: "TopHeader",
  components: {
    CommonChatWindow
  },
  mixins: [APIProfile, mixinStore],
  props: {
    mid: String
  },
  data() {
    return {
      loggedin: false,
      alerts: [],
      copyAlerts: [],
      chatUsers: [],
      userRole: ""
    };
  },
  created() {
    this.haddleRole();
  },
  watch: {
    $route(to, from) {
      this.connectSocket();
    }
  },
  sockets: {},
  mounted() {
    this.connectSocket();
  },
  methods: {
    haddleRole: function() {
      let mdata = JSON.parse(localStorage.merchant_data);
      this.userRole = mdata.role;
    },
    getChatUsers: async function() {
      let response = await this.getMerchantChatUsers();
      if (response.length > 0) {
        for (let index = 0; index < response.length; index++) {
          if (response[index]._id === null) {
            response.splice(index, 1);
          }
        }
        this.chatUsers = response;
        this.$refs.childChatComm.userChatByTraget(this.chatUsers[0]._id);
      }
    },
    connectSocket: async function() {
      try {
        let res = await this.getMerchantAlert();
        
        // let arr = res.filter(function(val) {
        //   return val.notification_type === 4;
        // });
        this.alerts = res;

      } catch (error) {
        throw error;
      }
      
      let response = await this.getProfileBacicInfo();
      this.$socket.client.io.opts.query = {
          token: response._id,
          token_type: 2
      }

      this.$socket.client.connect();
      let self = this;
      this.$socket.client.on('socket/merchant/notification', function(data) {
          if(data.payload.data.length > 0){
              self.alerts = data.payload.data;
              // Object.assign(this.alerts, data.payload.data) 
          }
      });
      let responsestore = await this.getStores();
    },
    resetWindow() {
      var element = document.getElementById("dropdownwindow");
      element.style.opacity = null;
      element.style.visibility = null;
    },
    alertPush: async function(item) {
      if (this.userRole === 1) {
        this.$store.state.merchant_selectedstore = item.store;

        localStorage.setItem("merchant_selectedstore", item.store);
        this.$store.dispatch("fetchMerchant_selectedstore");

        this.$router.push({
          name: "Product Configure",
          params: { id: item.product }
        });
      } else if (this.userRole === 2) {
        this.$router.push({ name: "Merchant Product" });

        localStorage.setItem("store_manager_pId", item.product);
        this.$store.dispatch("fetchStoreMerchant_pId");
      }
      // document.getElementById("dropdownwindow").style.opacity = 0;
      // document.getElementById("dropdownwindow").style.visibility = 'hidden';
      // let obj = {
      //     "_id": aid
      // }
      // this.$router.push('/merchant-notification-center?type=' + notification_type).catch(err => {});
      // let response = await this.putAlertClick(obj);
      // switch (notification_type) {
      //     case 1:
      //         // 1 - customer-question
      //         break;
      //     case 2:
      //         //  2 - order
      //         break;
      //     case 3:
      //         //  3 - announce
      //         break;
      //     case 4:
      //         // 4 - product
      //         break;
      //     case 5:
      //         //  5 - rating
      //         break;
      //     default:
      //         break;
      // }
    },
    handleByOnSlt: function() {
      this.$router.push("/").catch(err => {});
    },
    handleByOnSltlanding: function() {
      this.$router.push("merchant-landing").catch(err => {});
    },
    handleMerchantProfile: function() {
      this.$router.push("profile-configure").catch(err => {});
    },
    login() {
      this.$router.push("/buyer-login").catch(err => {});
    },
    logout() {
      localStorage.removeItem("order_group_id");
      localStorage.removeItem("pending_order_group_id");
      localStorage.removeItem("cancelled_order_group_id");
      this.$store.dispatch("logoutMerchant");
      this.$router.push("/merchant-landing-new").catch(err => {});
    },
    register() {
      this.$router.push("/merchant-register").catch(err => {});
    },
    pushMerchantProfile() {
      this.$router.push("/merchant-profile").catch(err => {});
    },
    merchantLanding() {
      this.$router.push("/merchant-profile").catch(err => {});
    },
    merchantOderGroup() {
      if (this.$store.state.merchant_accessToken !== null) {
        this.$router.push("/merchant-dashbord").catch(err => {});
      }
    }
  }
};
</script>
<style scoped>
.hoverTable tr:hover {
  background: #e4e3e3;
}
.Rectangle-13 {
  position: absolute;
  width: 84px;
  height: 19px;
  border-radius: 3px;
  background-color: #ffcd05;
  line-height: 16px;
  /* padding-left: 3px;
        padding-left: 3px; */
  /* margin-top: 20px; */
  bottom: 0;
  margin-left: 7px;
  text-align: center;
}

.Rectangle-text {
  width: 71px;
  height: 10px;
  /* font-family: Roboto; */
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #005baa;
}
.common-home .typeheader-1 {
  background: #5779ae;
  margin-bottom: 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.merchant-new-hdr{
  background: #5779ae !important;
  margin-bottom: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.typeheader-1 .merch-header-middle{
    background: #5779ae !important;
    margin-top: 0px !important;
}
/* .merch-logo {
  padding-top: 15px;
} */
/* #ml-top-banne {
  margin-top: -15px !important;
} */
.merch-top-links li a:hover {
  color: #ffd338;
}
.merch-top-links .fa-unlock:hover {
  color: #ffd338;
}
/* .logo-bg {
  position: absolute;
  background-color: #de1a22;
  left: -60px;
  padding-left: 50px;
  top: 0px !important;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 80px;
  border-bottom-right-radius: 90px;
} */

.text-color-headder {
  color: #ffffff;
}

.text-color-headder:hover {
  color: #000000;
}

/* .logo-bg img {
  width: 100%;
} */
.header-portal-title {
  text-align: right;
  padding-top: 10px;
  font-size: 17px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 0.07345em;
}

/* Header new styles */
/* .newlogo-block {
  overflow: hidden;
  padding-left: 0px;
} */
/* #merchhdrlogo {
  width: 100%;
  height: 90px;
  margin: 0 0 0 -20px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: #de1a22;
  overflow: hidden;
  position: relative;
  outline: 1px solid red;
} */

/* #merchhdrlogo img {
  display: block;
  width: 45%;
  margin: 14px auto 0 auto;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
} */
.header-bottom a{
  color: #ffffff;
}
.admin-heading{
  color: #ffffff;
}
.typeheader-1 .signin-w{
  font-size: 14px;
  color: red;
}
.merch-logo-section img{
  width: 45% !important;
}
.typeheader-2 .merchant-header-bottom{
  background: #5779ae;
}
.typeheader-1 .header-bottom .container .merchant-header-link-text{
  width: 100%;
  /* outline: 1px solid red; */
}

@media screen and (min-width: 2200px) {
  .navbar-brand img {
    min-height: 80px !important;
    width: 63% !important;
  }
}

@media screen and (min-width: 2500px) {
  .navbar-brand img {
    min-height: 80px !important;
    width: 60% !important;
  }
}

@media screen and (max-width: 1400px) {
  .navbar-brand img {
    width: 90% !important;
  }
}

/* New media queries */
@media screen and (max-width: 1024px) {
  /* #merchhdrlogo img {
    width: 55%;
  } */
}
@media screen and (max-width: 991px) {
  .merch-header-middle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .merchant-header-portal-title{
    width: 100%;
    padding-right: 4%;
    margin-bottom: 15px;
  }
  .typeheader-1 .header-bottom .container .merchant-header-link-text{
    padding-right: 2%;
    position: relative;
  }
  .merch-logo-section{
    width: 100%;
    text-align: center;
  }
  .merch-logo-section img{
    width: 20% !important;
  }
}
@media screen and (max-width: 875px) {
  /* #merchhdrlogo img {
    margin: 17px auto 0 auto;
  } */
}
@media screen and (max-width: 768px) {
  /* .buyer-header-middle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  } */
  /* #merchhdrlogo img {
    width: 50%;
  } */
  /* .newlogo-block {
    width: 40%;
  } */
  /* .header-portal-title {
    width: 60%;
  } */
 
}
@media screen and (max-width: 575px) {
  /* #merchhdrlogo img {
    width: 55%;
  } */
  /* .newlogo-block {
    width: 45%;
  } */
  /* .header-portal-title {
    width: 55%;
    font-size: 15px;
  } */
}
@media screen and (max-width: 475px) {
  .merchant-new-hdr .signin-w{
      padding-left: 12px !important;
  }
  .merchant-new-hdr .signin-w ul li a{
      font-size: 11px !important;
  }
}






@media screen and (max-width: 425px) {
  /* #merchhdrlogo img {
    width: 55%;
    margin: 20px auto 0 auto;
  } */
}
@media screen and (max-width: 350px) {
  /* .newlogo-block {
    width: 100%;
    padding-right: 0px;
  } */
  .header-portal-title {
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
  }
  /* #merchhdrlogo {
    margin-left: 0px;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  } */
  /* #merchhdrlogo img {
    display: block;
    width: 40%;
    margin: 17px auto 0 auto;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  } */
   .merchant-new-hdr .signin-w{
      padding-left: 10px !important;
  }
  .merchant-new-hdr .signin-w ul li a{
      font-size: 9px !important;
  }  
}
@media screen and (min-width: 1575px) {
  /* .newlogo-block {
    width: 20%;
  } */

  /* #merchhdrlogo img {
    width: 47.5%;
  } */
}
@media screen and (min-width: 1775px) {
  /* #merchhdrlogo img {
    margin: 10px auto 0 auto;
  } */
}
@media screen and (min-width: 1920px) {
  /* #merchhdrlogo img {
    width: 42%;
    margin: 12px auto 0 auto;
  } */
}
@media screen and (min-width: 2000px) {
  /* #merchhdrlogo img {
    width: 35%;
    margin: 15px auto 0 auto;
  } */
}
@media screen and (min-width: 2300px) {
  /* #merchhdrlogo img {
    width: 32%;
    margin: 16px auto 0 auto; */
  /* } */
}
@media screen and (max-width: 1280px) {
  .tble-alerts {
    width: 400px !important;
  }
}
@media (min-width: 320px) {
  /* header .dropdown-menu { */
    /* -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: rotateX(-70deg);
    transform: rotateX(-70deg);
    transition: transform 0.4s ease 0s, opacity 0.2s ease 0s, visibility 0.2s ease 0s;
    opacity: 0; */
    /* visibility: hidden; */
    /* display: block; */
  /* } */
}

/* ASHEN CSS */
/* .nav-logo {
  width: 100%;
  background: #de1a22;
  overflow: hidden;
  position: relative;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
} */
</style>
