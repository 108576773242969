<template>
  <div class="wrapper" style="background-repeat: no-repeat;
  background-position: right top; background-attachment: fixed;"> 
    <div style="background-image: url(/q_autohttps://res.cloudinary.com/persystance-network/image/upload/q_auto/v1589124594/slt/portal_user/1589124594178.png);
  background-repeat: no-repeat; background-attachment: fixed;">
      <div class="main-panel">
        <!-- <TopNavbar v-if="!landing"></TopNavbar>
        <TopNavbarLanding v-if="landing" /> -->
        <TopNavbar />
        <div class="container" v-bind:class="{ landing: landing }">
          <div class="row">
            <div class="col-md-12 banner-cont">
              <dashboard-content></dashboard-content>
            </div>
          </div>
        </div>
        <content-footer  class="footer-container typefooter-1"></content-footer>
        </div>
    </div>
  </div>
</template>
<script>
import TopNavbar from './MerchantDashboard/TopNavbar.vue'
// import TopNavbar from '../containers/BuyerDashboard/home_page_top_header'
import TopNavbarLanding from './MerchantDashboard/TopNavbarlanding.vue'
import DashboardContent from './MerchantDashboard/Content.vue'
import ContentFooter from './MerchantDashboard/ContentFooter.vue'
export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    TopNavbarLanding
  },
  watch: {
    $route(to, from) {
      this.hanldeTopNav();
    },
  },
  created(){
    this.hanldeTopNav();  
  },
  methods: {
    hanldeTopNav(){
      if(this.$route.name === 'Password Reset' ){
        this.landing = true;
      } else {
        this.landing = false;
      }
    }
  }
}
</script>
<style scoped> 
  .landing {
    width: 100% !important;
    max-width: fit-content !important;
  }
  @media screen and (max-width: 1290px){
    .banner-cont{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
</style>
