<template>
  <div class="row">
    <div class="col-md-4">
      <h4 class="modal-title" style="margin-top:10px;"><strong>&nbsp;Chats</strong></h4>
      <hr>
      <ul class="nav nav-pills nav-stacked">
        <li role="presentation" :class="[item.active ? 'active': '']"  v-for="(item, index) in chatTargets" :key="index" @click="userChatByTraget(item._id);"><a v-if="utype == 'buyer'">{{item.storeData.store_name}}</a> <a v-else>{{item.userData.first_name + ' '+ item.userData.last_name}}</a></li>

      </ul>
    </div>
    <div id="frame" class="col-md-8" style="border-left: 1px solid #eee;" >
      <button style="font-size: 32px;" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      
      
      <div  id="chatwindow" style="max-height:440px; min-height: 50px; overflow-y:scroll;">
        <div class="messages " style="padding-left: 0px;">
          <img v-if="showChatLoading" style="position: absolute; width: 50px; height: 50px; z-index: 100;" src="https://i.pinimg.com/originals/04/3b/7a/043b7a72e6f3fd332bdf88e94f7ecff3.gif" />
          <ul>
            <li :class="assignClass(item.user_chat)"  v-for="(item, index) in userchatdata" :key="index">
              
              <p style="text-align: end; padding-right: 5px">
                <img v-if="item.user_chat && item.user.image" :src="item.user.image" height="22" width="22" alt="" />
                <img v-else-if="item.user_chat" src="assets/image/profile/default_profile_image.jpg" height="22" width="22" alt="" />
              </p>

              <p style="text-align: inherit;">
                <img v-if="!item.user_chat" :src="item.merchant.image" height="22" width="22" alt="" />
              </p>

              <div class="row" style="text-align: center; background-color: #f7f7f7; font-size: smaller; margin: 10px;border-radius: 5px;" v-if="checkProductID(item)">
                <a @click="productPush(item.product._id)">
                <div class="col-2">
                  <img  :src="item.product_image" style="height: 40px; width: auto;margin: 0;  margin-top: 4px;"  alt="" />
                </div>
                <div class="col-10">
                  <b style="padding: 10px;">{{item.product.product_name}}</b>
                   <br>
                  <span style="float: right; padding-right: 5px;">{{item.date.replace('Z', '').replace('T', ' ')}}</span>
                </div>
                </a>
                
              </div>
              
              <p v-if="!checkProductID(item) && item.user_chat" style="text-align: right; border: 2px solid #dedede; background-color: #f1f1f1; padding-right: 5px">
                <b>{{item.chat}}</b>
                <br>
                <small>{{item.date.replace('Z', '').replace('T', ' ')}}</small>
              </p> 

              <p v-if="!checkProductID(item) && !item.user_chat" style="text-align: inherit; border: 2px solid #aed7ff; background-color: #d5e6f7; padding-left: 5px;">
                <b>{{item.chat}}</b>
                <br>
                <small>{{item.date.replace('Z', '').replace('T', ' ')}}</small>
              </p>

            </li> 
          </ul>
        </div>
      </div>
        
      <div class="row">
        <div class="col-md-12" style="padding-top: 5px;">
          <span v-if="utype == 'buyer'">
      <h5 v-if="chatStoreID === productData.sid" class=" " style="margin-top: 10px;background-color: white;border-style: solid;border-color: rgb(189, 189, 189); border-style: dashed;"><img :src="productData.image" height="50px" /> <strong style="width: 340px;
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"> {{ productData.name }} </strong><small><button style="margin-left: 2px;
    border-radius: 5px;" class="btn btn-sm btn-primary" @click="itemToChat();">Add To Chat</button></small></h5>
      </span>
          <form class="form-inline">
            <!-- <button type="submit" class="btn btn-default">Send</button>
            &nbsp;
              <textarea class="form-control" rows="1"></textarea>
            &nbsp;
            <button type="submit" class="btn btn-default">Send</button> -->
            <div class="form-group" style="width:100%;">
              <div class="input-group" style="width:100%;">
              <div class="input-group-addon"><i class="fa fa-paperclip"></i></div>
              <textarea v-model="chatinput" class="form-control" rows="1"></textarea>
              <div class="input-group-addon" @click="chatSend()"><i class="fa fa-paper-plane"></i></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/buyer/APIProfile";
import mixinProfileMerchant from "@/mixins/merchant/APIProfile";
import { async } from 'q';
export default {
  mixins: [mixinCommon, mixinProfile, mixinProfileMerchant],
  props: {
    title: String,
    utype: String,
    userID: String,
    data: Array,
    chatTargets: Array,
    productData: Object,
    mainStoreID: String,
    mainMerchantID: String,
  },
  data: function () {
    return { 
      userchatdata: [],
      chatinput: '',
      chatMerchantID: '',
      chatStoreID: '',
      showChatLoading: true,
      uID: ''
    }
  },
  created(){ 
  },
  methods: { 
    assignClass(status){
      switch (this.utype) {
        case "buyer":

          if(status){
            return 'replies';
          } else {
            return 'sent';
          }          
          break;

        case "merchant":

          if(status){
            return 'sent';
          } else {
            return 'replies';
          }          
          break;

        default:
          break;
      }
      
    },
    checkProductID(item){
      if (item.hasOwnProperty("product_image")) {
        return true
      } else {
        return false
      }
    },
    scrollChatWindow(){
      setTimeout(function () {			
        var objDiv = document.getElementById("chatwindow");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 500);
    },
    chatSend(){
      let chatData = {}
      if (this.utype === "buyer") {
        chatData = {
          // product: this.selectedProduct.mainpid,
          // product_image: this.selectedproductImage,
          user: this.userID,
          merchant: this.chatMerchantID,
          store: this.chatStoreID,
          chat: this.chatinput,
          user_chat:true
        }
      }

      if (this.utype === "merchant") {
        chatData = {
          // product: this.selectedProduct.mainpid,
          // product_image: this.selectedproductImage,
          user: this.uID,
          merchant: this.chatMerchantID,
          store: this.chatStoreID,
          chat: this.chatinput,
          merchant_chat:true
        }
      }

      // console.log(chatData);
 
      this.$socket.client.emit('socket/product/chat', chatData, 
                    function(confirmation){
                            console.log(confirmation);
                    }
                  ); 

      this.chatinput = '';
    }, 
    userChatByTraget: async function(tid) {

      

      if (this.utype === "buyer") {

        this.showChatLoading = true;
        this.userchatdata = [];
        let response = await this.getUserChatByStoreID(tid);
       
        this.userchatdata = response;
        // console.log("chat massages0")
        // console.log(this.userchatdata)
        

        if(response.length > 0){
          this.chatStoreID = response[0].store;
          this.chatMerchantID = response[0].merchant._id;
        } else {
          this.chatStoreID = this.mainStoreID;
          this.chatMerchantID = this.mainMerchantID; 
        }

        this.scrollChatWindow();

        this.$socket.client.io.opts.query = {
          token: this.userID,
          token_type: 3
        }


        this.$socket.client.connect(); 
        let self = this;
        this.$socket.client.on('socket/product/chat/'+ tid, function(data){
          if(data.payload.data.length > 0){
            self.userchatdata = data.payload.data;
            // console.log("chat massages1")
            // console.log(self.userchatdata)
            //Object.assign(this.alerts, data.payload.data) 
            // console.log(data.payload.data);
          }

          self.scrollChatWindow();
        });
        
      }  


      if (this.utype === "merchant") {
        this.uID = tid;
        this.showChatLoading = true;
        this.userchatdata = [];
        let response = await this.getMerchantChatByUserID(tid);
        this.userchatdata = response;
        // console.log("chat massages2")
        // console.log(this.userchatdata)
        

        if(response.length > 0){
          this.chatStoreID = response[0].store;
          this.chatMerchantID = response[0].merchant._id;
        }

        this.scrollChatWindow();

        this.$socket.client.io.opts.query = {
          token: this.userID,
          token_type: 3
        }


        this.$socket.client.connect(); 
        let self = this;
        this.$socket.client.on('socket/product/chat/'+ tid, function(data){
          if(data.payload.data.length > 0){
            self.userchatdata = data.payload.data;
            // console.log("chat massages3")
            // console.log(self.userchatdata)
            //Object.assign(this.alerts, data.payload.data) 
            self.chatTargets.forEach(element => {
              if(element._id === self.userchatdata[0].user._id){
                element.active = true;
              } else {
                element.active = false;
              }			
            });
            // console.log(data.payload.data);
          }

          self.scrollChatWindow();
        });

        

      }
      
      this.chatTargets.forEach(element => {
        if(element._id === tid){
          element.active = true;
        } else {
          element.active = false;
        }			
      });
      
      this.showChatLoading = false;
    },
    productPush(pid){
      this.$router.push('/product?pid=' + pid);
    },
    itemToChat(){
      let chatData = {
        product: this.productData.id,
        product_image: this.productData.image,
        user: this.userID,
        merchant: this.chatMerchantID,
        store: this.chatStoreID,
        chat: '  ',
        user_chat:true
      }
      // console.log(chatData)
      this.$socket.client.emit('socket/product/chat', chatData, 
                    function(confirmation){
                            console.log(confirmation);
                    }
                  );
    }
  }
}
</script>
